import {
  FILE_ERROR_FILE_SIZE,
  FILE_ERROR_FILE_TYPE,
  FILE_SHARE_MODE_ALL,
  FILE_SHARE_MODE_NONE,
  FILE_SHARE_MODE_SELECTED,
} from "core/consts";
import { ALLOWED_EXTENSIONS_HIS, MAX_FILE_SIZE } from "core/model/files/config";
import { decryptFromSession } from "core/seald/sessions";
import {
  FileError,
  FileHIS,
  FileHISStateful,
  FileTypeHIS,
  Filev2Stateful,
} from "core/types";
import { FileName } from "ds_legacy/components/Tables/GeneralTable/CustomCells/Files";
import { isFileHISStateful } from "dsl/organisms/FileTables";
import { FileShareResultWithInitialValues } from "dsl/organisms/Modals/FileSharingModal";
import { ComponentProps } from "react";
import Translations from "translations/types";

export async function getFileName({
  canAccessPatientData,
  file,
}: {
  canAccessPatientData: boolean;
  file: FileHISStateful | Filev2Stateful;
}): Promise<ComponentProps<typeof FileName>> {
  if (!canAccessPatientData) {
    return { value: "blurred", shouldBlur: true };
  }

  if (isFileHISStateful(file)) {
    return { value: file.document_file_name, shouldBlur: false };
  }

  const value = await decryptFromSession({
    encryptedMessage: file.file_name?.seald_content,
    context: "file",
  });

  return {
    value: value ?? "blurred",
    shouldBlur: !value,
    isNewVersionAvailable: file.isNewVersionAvailable,
  };
}

export function getSharedWith({
  share_mode,
  translations,
}: Pick<FileShareResultWithInitialValues, "share_mode"> & {
  translations: Translations;
}): string {
  let result = "";
  if (share_mode === FILE_SHARE_MODE_NONE) {
    result = translations.fileSection.fileListComponent.shareOptions.noOne;
  }
  if (share_mode === FILE_SHARE_MODE_SELECTED) {
    result =
      translations.fileSection.fileListComponent.shareOptions.selectedReceivers;
  }
  if (share_mode === FILE_SHARE_MODE_ALL) {
    result =
      translations.fileSection.fileListComponent.shareOptions.allReceivers;
  }

  return result;
}

export function validateFileHIS({ fileHIS }: { fileHIS: FileHIS }): {
  isValid: boolean;
  type: FileTypeHIS | null;
  unavailable_reason: FileError | null;
} {
  const { document_file_name, document_file_size } = fileHIS;
  const fileParts = document_file_name?.split(".");
  const type = fileParts?.[fileParts.length - 1]?.toLowerCase() as FileTypeHIS;

  if (!document_file_name || !ALLOWED_EXTENSIONS_HIS.includes(type)) {
    return {
      type,
      unavailable_reason: FILE_ERROR_FILE_TYPE,
      isValid: false,
    };
  }

  if (!document_file_size || document_file_size > MAX_FILE_SIZE) {
    return {
      type,
      unavailable_reason: FILE_ERROR_FILE_SIZE,
      isValid: false,
    };
  }

  return {
    type,
    unavailable_reason: null,
    isValid: true,
  };
}
